import "react-datepicker/dist/react-datepicker.css";

import React, { useState, useEffect } from 'react';

import getUltimaActualizacion from '../../../../services/getUltimaActualizacion'
import getFincas from '../../../../services/getFincas'
import Cargando from '../../../../components/Cargando'

// https://reactdatepicker.com/
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';

import "./ComparativaContent.css"
import { GraficaFinca } from "../GraficaFinca";

const ComparativaContent = () => {
    registerLocale('es', es)

    const hoy = new Date();
    const primerDiaMes = new Date(hoy.getFullYear(), hoy.getMonth(), 1);

    const [frecuencia, setFrecuencia] = useState(15)
    const [fechaDesde, setFechaDesde] = useState(primerDiaMes);
    const [fechaHasta, setFechaHasta] = useState(new Date());
    const [ultimaActualizacion, setUltimaActualizacion] = useState([]);
    const [loadingUltimaActualizacion, setLoadingUltimaActualizacion] = useState(false)
    const [fincas, setFincas] = useState([])
    const [setLoadingFincas] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            setLoadingUltimaActualizacion(true)
            getUltimaActualizacion()
                .then((res) => {
                    setUltimaActualizacion(res.data.ultima_actualizacion);
                    setLoadingUltimaActualizacion(false);
                })
                .catch((err) => {
                    // console.log(err)
                }
                )

            setLoadingFincas(true)
            getFincas()
                .then((res) => {
                    setFincas(res.data.fincas);
                    setLoadingFincas(false);
                })
                .catch((err) => {
                    // console.log(err)
                }
                )
        };

        fetchData();
        return () => {
        }
    }, [])

    const handleChangeFrecuencia = (event) => {
        setFrecuencia(event.target.value);
    }

    const Filtro = () => {

        return (
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label>Frecuencia de muestreo</label>
                                <select className="form-control" onChange={handleChangeFrecuencia}>
                                    <option value="15">15min</option>
                                    <option value="30">30min</option>
                                    <option value="60">60min</option>
                                </select>
                            </div>
                        </div>
                        <div className="col">
                            <label style={{ display: "block" }}>Desde</label>
                            <DatePicker
                                selected={fechaDesde}
                                onChange={date => setFechaDesde(date)}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                            />
                        </div>
                        <div className="col">
                            <label style={{ display: "block" }}>Hasta</label>
                            <DatePicker
                                selected={fechaHasta}
                                onChange={date => setFechaHasta(date)}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const DatosSensores = () => {
        return (
            <div className="row">
                {
                    ultimaActualizacion &&
                    ultimaActualizacion.map((item, index) => {
                        return (
                            <div key={index} className="col-sm-6 col-md-6 col-lg-3 mt-3">
                                <div className="card">
                                    <div className="content">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="icon-big text-center">
                                                    <i className="teal fas fa-thermometer-half"></i>
                                                </div>
                                            </div>
                                            <div className="col-sm-8">
                                                <div className="detail">
                                                    <span className="number">{item.finca_nombre}</span>
                                                    <p className="detail-subtitle">{item.sensor_nombre}</p>
                                                    <span className="number"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="footer">
                                            <hr />
                                            <div className="stats">
                                                <i className="fas fa-calendar"></i> {item.fecha}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }


    return (
        <>
            {
                loadingUltimaActualizacion ?
                    <Cargando /> :
                    <DatosSensores />
            }

            <Filtro />

            {

                
                fincas &&
                fincas.map((item, index) => {
                    console.log(item)
                    return (
                        <>    
                           
                            
                        <GraficaFinca
                            finca={item}
                            frecuencia={frecuencia}
                            fechaDesde={fechaDesde}
                            fechaHasta={fechaHasta}
                            />
                            </>
                    )
                })


            }

        </>
    )
}

export default ComparativaContent;