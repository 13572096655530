import React from 'react';
import "../../assets/css/master.css";

import TemplateDashboard from "../../components/TemplateDashboard";
import HomeContent from "./components/HomeContent"
  
const Home = () => {

    return (
    <>

        <TemplateDashboard pretitle="Vista general" title="Datos generales">
            <HomeContent />
        </TemplateDashboard>
        
    </>
    )
};
  
export default Home;