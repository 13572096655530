import React, { useState, useEffect } from 'react';

import getRiegoHistorico from '../../../../services/getRiegoHistorico'
import Cargando from '../../../../components/Cargando'

// https://reactdatepicker.com/
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';

import "./RiegoContent.css"
import getFincas from '../../../../services/getFincas';
import getChannel from '../../../../services/getChannel';

import TextInputObservaciones from '../TextInputObservaciones'

const RiegoContent = () => {
    registerLocale('es', es)

    const hoy = new Date();
    const primerDiaMes = new Date(hoy.getFullYear(), hoy.getMonth(), 1);

    const [historico, setHistorico] = useState([]);
    const [loadingHistorico, setLoadingHistorico] = useState(false)
    const [fechaDesde, setFechaDesde] = useState(primerDiaMes);
    const [fechaHasta, setFechaHasta] = useState(new Date());
    const [fincas, setFincas] = useState([])
    const [fincaId, setFincaId] = useState(1);
    const [channel, setChannel] = useState([])
    const [channelId, setChannelId] = useState('*');
    
    useEffect(() => {
        getFincas().then((res) => {
            setFincas(res.data.fincas)
        })
        setFincaId(1)
        setChannelId('*')
    }, [])

    useEffect(() => {
        fincaId &&
            getChannel(fincaId).then((res) => {
                setChannel(res.data.channel)
            })
    }, [fincaId])

    useEffect(() => {
        const fetchData = async () => {
            setLoadingHistorico(true)
            getRiegoHistorico({ fincaId, channelId, fechaDesde, fechaHasta })
                .then((res) => {
                    setHistorico(res.data.historico);
                    setLoadingHistorico(false);
                })
                .catch((err) => {
                    // console.log(err)
                }
                )

        };

        fetchData();
        return () => {
        }
    }, [fincaId, channelId, fechaDesde, fechaHasta])

    const handleChangeFincaId = (event) => {
        setFincaId(event.target.value);
    }
    const handleChangeChannelId = (event) => {
        setChannelId(event.target.value);
    }
    const handleChangeFechaDesde = (fecha) => {
        setFechaDesde(fecha)
    }
    const handleChangeFechaHasta = (fecha) => {
        setFechaHasta(fecha)
    }

    const Filtro = () => {

        return (
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label>Finca</label>
                                <select value={fincaId} className="form-control" onChange={handleChangeFincaId}>
                                    {fincas &&
                                        fincas.map((item, index) => {
                                            return <option key={index} value={item.id}>{item.nombre}</option>
                                        })}
                                </select>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label>Canales</label>
                                <select value={channelId} className="form-control" onChange={handleChangeChannelId}>
                                    <option value="*">Todos</option>
                                    {channel &&
                                        channel.map((item, index) => {
                                            return <option key={index} value={item.ChannelID}>{item.Nombre}</option>
                                        })}
                                </select>
                            </div>
                        </div>
                        <div className="col">
                            <label style={{ display: "block" }}>Desde</label>
                            <DatePicker
                                selected={fechaDesde}
                                onChange={fecha => handleChangeFechaDesde(fecha)}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                            />
                        </div>
                        <div className="col">
                            <label style={{ display: "block" }}>Hasta</label>
                            <DatePicker
                                selected={fechaHasta}
                                onChange={fecha => handleChangeFechaHasta(fecha)}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
    const DatosHistorico = () => {
        
        return (
            <>
                <h3>Historico</h3>
                <table className="table table-striped table-hover table-sm">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Estado</th>
                            <th scope="col">Channel</th>
                            <th scope="col">Desde</th>
                            <th scope="col">Hasta</th>
                            <th scope="col">Tiempo (min)</th>
                            <th scope="col">Observaciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            historico &&
                            historico.map((item, index) => {

                                return (
                                    <tr key={item.id} >
                                        <td>
                                            {item.is_cerrado ? <span style={{ color: 'green' }}>[Cerrado] </span> : <span style={{ color: 'red' }}>[Abierto] </span>}
                                        </td>
                                        <td>
                                            {item.ChannelNombre}
                                        </td>
                                        <td>{item.fecha_inicio}</td>
                                        <td>{item.fecha_fin}</td>
                                        <td>{item.minutos}</td>
                                        <td >
                                            <TextInputObservaciones item={item} />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </>
        )
    }

    return (
        <>

            <Filtro />

            {
                loadingHistorico ?
                    <Cargando /> :
                    <DatosHistorico />
            }
            {/*
                ultimaActualizacion &&
                ultimaActualizacion.map((item, index) => {
                    return (
                        <GraficaSensor
                            sensor_nombre={item.sensor_nombre}
                            frecuencia={frecuencia}
                            fechaDesde={fechaDesde}
                            fechaHasta={fechaHasta}
                        />)
                })
            */}

        </>
    )
}

export default RiegoContent;