import axios from 'axios';
import {
    BASE_URL_SERVICE,
} from '../constants'

async function getUsuario (mail, password) {


	        try {
	            var config = {
	                headers: { 
	                    'content-type': 'application/x-www-form-urlencoded'
	                    // 'content-type': 'application/form-data'
	                }
	            };
                var params = new URLSearchParams();
                params.append('mail', mail);
                params.append('password', password);
	            const response = await axios.post( BASE_URL_SERVICE + 'usuario', params, config);

	            return response;
	        } catch (error) {
                return undefined;
	            // throw error;
	        }

}

export default getUsuario;