import Login from './pages/login';
import Logout from './pages/logout';
import Home from './pages/home';
import Sensores from './pages/sensores';
import Notas from './pages/notas';
import NotFound from './pages/notFound';
import ComparativaTemperatura from './pages/comparativaTemperatura';

import {
  BrowserRouter,
  Link,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import React from 'react';

import { useSelector } from "react-redux";

import {
  selectUsuarioSeleccionado,
} from "./redux/reducer/loginReducer";
import RiegoHistorico from './pages/riegoHistorico';


function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => authed === true
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
    />
  )
}

const RouteAbout = () => {
  return (
    <Route path="/about">
      About page
    </Route>
  );
}
const RouteHome = () => {
  return (
    <Route component={Home} path="/home"></Route>
  );
}
const RouteComparativaTemperatura = () => {
  return (
    <Route component={ComparativaTemperatura} path="/comparativa_temperatura"></Route>
  );
}
const RouteSensores = () => {
  return (
    <Route component={Sensores} path="/sensores"></Route>
  );
}
const RouteNotas = () => {
  return (
    <Route component={Notas} path="/notas"></Route>
  );
}
const RouteRiegoHistorico = () => {
  return (
    <Route component={RiegoHistorico} path="/riego_historico"></Route>
  );
}


export default function App(props) {
  const usuarioSeleccionado = useSelector(state => {
    return selectUsuarioSeleccionado(state);
  });

  return (

    <BrowserRouter>
      <Switch>

        {/* ZONA PUBLICA */}
        <Route component={Login} path="/login"></Route>
        <Route component={Logout} path="/logout"></Route>
        <Route exact path="/">
          <div className="container">
            {
              usuarioSeleccionado ?
                <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
                :
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }
            <Link className="btn btn-secondary btn-lg btn-block" to="/login">Login</Link>
            <Link className="btn btn-secondary btn-lg btn-block" to="/home">Home</Link>
          </div>
        </Route>

        {/* ZONA PRIVADA */}
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/about' component={RouteAbout} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/home' component={RouteHome} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/comparativa_temperatura' component={RouteComparativaTemperatura} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/sensores' component={RouteSensores} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/notas' component={RouteNotas} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/riego_historico' component={RouteRiegoHistorico} />

        {/* PAGINA NO ENCONTRADA */}
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>

  )
}