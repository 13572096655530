import React from 'react';
import "../../assets/css/master.css";

import TemplateDashboard from "../../components/TemplateDashboard";
import ComparativaContent from './components/ComparativaContent';

const ComparativaTemperatura = () => {

    return (
        <>

            <TemplateDashboard pretitle="Vista general" title="Comparativa de temperaturas por finca">
                <ComparativaContent />
            </TemplateDashboard>

        </>
    )
};

export default ComparativaTemperatura;