import React from 'react'
import './Cargando.css'

const Cargando = () => {
    return (
        <>
            <div className="d-flex justify-content-center text-teal">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </>
    )
}

export default Cargando