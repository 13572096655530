import axios from 'axios';
import {
    BASE_URL_SERVICE,
} from '../constants'

async function getMuestras (sensor_nombre, frecuencia, fechaDesde, fechaHasta) {
	try {
		var config = {
			headers: { 
				'Access-Control-Allow-Origin': '*',
				'content-type': 'text/json'
			}
		  };

		const response = await axios.get( BASE_URL_SERVICE + 'muestras', { params: {
			frecuencia,
			fechaDesde,
			fechaHasta,
			sensor_nombre
		  }}, config);

		return response;
	} catch (error) {
		return undefined;
		// throw error;
	}

}

export default getMuestras;