import * as React from 'react'
import updateRiegoHistoricoObservaciones from '../../../services/updateRiegoHistoricoObservaciones'

const TextInputObservaciones = ({ item }) => {
    const [observaciones, setObservaciones] = React.useState(item.observaciones)

    const handleChangeObservaciones = (event) => {
        event.preventDefault();

        const obs = event.target.value
        const riegoId = (event.target.dataset.id)
        
        setObservaciones(obs)

        const fetchData = async (_riegoId, _obs) => {
            
            updateRiegoHistoricoObservaciones({ riegoId: _riegoId, observaciones: _obs })
                .then((res) => {
            
                })
                .catch((err) => {
            
                }
            )

        };

        fetchData(riegoId, obs);
    }
    return (
        <input
            data-id={item.id}
            value={ observaciones }
            name="observaciones"
            onChange={(e) => handleChangeObservaciones(e)}
        />
    )
 }

 export default TextInputObservaciones