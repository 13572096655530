import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    Redirect
} from 'react-router-dom';

import { logoutUser } from "./../../redux/actions/login.action";


const Logout = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        
        console.log("Ejecuto salir");

        dispatch(logoutUser());

    }, []);

    return (
        <>
            Saliendo de la aplicación...
            <Redirect to="/home" />
        </>

        
    );
}

export default Logout;