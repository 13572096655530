import axios from 'axios';
import {
	BASE_URL_SERVICE,
} from '../constants'

async function getFincaTemperatura(finca_id, frecuencia, fechaDesde, fechaHasta) {
	try {
		var config = {
			headers: {
				'Access-Control-Allow-Origin': '*',
				'content-type': 'text/json'
			}
		};

		const response = await axios.get(BASE_URL_SERVICE + 'finca_temperatura', {
			params: {
				finca_id,
				frecuencia,
				fechaDesde,
				fechaHasta,
			}
		}, config);

		return response;
	} catch (error) {
		return undefined;
		// throw error;
	}

}

export default getFincaTemperatura;