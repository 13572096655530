import axios from 'axios';
import {
	BASE_URL_SERVICE,
} from '../constants'

async function updateRiegoHistoricoObservaciones({ riegoId, observaciones }) {
	try {
		var config = {
			headers: {
				'Access-Control-Allow-Origin': '*',
				'content-type': 'text/json'
			}
		};

		const response = await axios.get(BASE_URL_SERVICE + 'update_riego_historico_observaciones', {
			params: {
				riegoId,
				observaciones
			}
		}, config);

		return response;
	} catch (error) {
		return undefined;
		// throw error;
	}

}

export default updateRiegoHistoricoObservaciones;