import React from 'react';
import "../../assets/css/master.css";

import TemplateDashboard from "../../components/TemplateDashboard";
import RiegoContent from './components/RiegoContent';

const RiegoHistorico = () => {

    return (
        <>

            <TemplateDashboard pretitle="Riego" title="Historico">

                <RiegoContent />

            </TemplateDashboard>

        </>
    )
};

export default RiegoHistorico;