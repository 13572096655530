import React, { useState, useEffect } from 'react';
import getMuestras from '../../../../services/getMuestras'
import Cargando from '../../../../components/Cargando'
import Chart from "react-apexcharts";

export const GraficaSensor = (props) => {
  let {
    sensor_nombre,
    frecuencia,
    fechaDesde,
    fechaHasta
  } = props

  const [muestras, setMuestras] = useState([]);
  const [loadingMuestras, setLoadingMuestras] = useState(false)
  const [numMuestras, setNumMuestras] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      setLoadingMuestras(true)
      getMuestras(sensor_nombre, frecuencia, fechaDesde, fechaHasta)
        .then((res) => {

          res.data.muestras && setMuestras(res.data.muestras);
          res.data.muestras && setNumMuestras(res.data.num_muestras)


          setLoadingMuestras(false);

        })
        .catch((err) => {
          // console.log(err)
        }
        )

    };

    fetchData();
  }, [frecuencia, fechaDesde, fechaHasta])

  const state = {
    options: {
      chart: {
        id: "basic-bar",
        height: 250,
        type: 'line',
        // dropShadow: {
        //   enabled: true,
        //   color: '#000',
        //   top: 18,
        //   left: 7,
        //   blur: 10,
        //   opacity: 0.2
        // },
        toolbar: {
          show: false
        }
      },


      annotations: {
        xaxis: [
          {
            x: new Date('2021-03-02').getTime(),
            borderColor: '#775DD0',
            label: {
              style: {
                background: '#775DD0',
                color: '#fff',
              },
              text: 'X-axis annotation - 22 Nov'
            }
          }
        ]
      },

      // annotations: {
      //   xaxis: [
      //     {
      //       x: new Date('2021-03-02').getTime(),

      //       color: '#fff',
      //       label: {
      //         style: {
      //           borderColor: '#000',
      //         },
      //         text: 'X-axis annotation - 22 Nov'
      //       }
      //     }
      //   ]
      // },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',

        // curve: 'straight',
        width: 2
      },

      xaxis: {
        categories: muestras.fechas_actual, // [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
        type: 'datetime',
        // tickPlacement: 'on',
        //tickAmount: 15,
        labels: {
          // rotate: 15,
          formatter: function (value, timestamp, opts) {
            const m = new Date(value);
            const dateString = m.getUTCDate().toString().padStart(2, "0") + "/" + (m.getUTCMonth() + 1).toString().padStart(2, "0") + "/" + m.getUTCFullYear() + " " + m.getUTCHours().toString().padStart(2, "0") + ":" + m.getUTCMinutes().toString().padStart(2, "0") + ":" + m.getUTCSeconds().toString().padStart(2, "0");

            return dateString
            // return opts.dateFormatter(new Date(timestamp), 'dd MMM')
          }
        }
      }
    },
    series: [
      {
        name: "Temperatura",
        data: muestras.temperaturas_actual // [30, 40, 45, 50, 49, 60, 70, 91]
      },
      {
        name: "Humedad",
        data: muestras.humedades_actual // [35, 45, 50, 55, 59, 70, 80, 101]
      }
    ],



  }

  const Grafica = () => {
    return (
      numMuestras === 0 ?
        <div className="alert alert-info mt-3">No hay datos para mostrar</div>
        :
        <Chart
          options={state.options}
          series={state.series}
          type="line"
        />
    )
  }
  return (
    <div>
      {sensor_nombre}

      {
        loadingMuestras ?

          <Cargando /> :

          <Grafica />
      }

    </div>
  )
}
