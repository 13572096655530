import axios from 'axios';
import {
    BASE_URL_SERVICE,
} from '../constants'

async function getUltimaActualizacion () {
	try {
		var config = {
			headers: { 
				'Access-Control-Allow-Origin': '*',
				'content-type': 'text/json'
			}
		  };

		const response = await axios.get( BASE_URL_SERVICE + 'ultima_actualizacion', {}, config);

		return response;
	} catch (error) {
		return undefined;
		// throw error;
	}

}

export default getUltimaActualizacion;