import React, { useState, useEffect } from 'react';
import Cargando from '../../../../components/Cargando'
import Chart from "react-apexcharts";
import getFincaTemperatura from '../../../../services/getFincaTemperatura';

export const GraficaFinca = (props) => {
  let {
    finca,
    frecuencia,
    fechaDesde,
    fechaHasta
  } = props

  const [muestras, setMuestras] = useState([]);
  const [fechas, setFechas] = useState([]);
  // const [muestras2, setMuestras2] = useState([]);
  const [loadingMuestras, setLoadingMuestras] = useState(false)
  const [numMuestras, setNumMuestras] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      setLoadingMuestras(true)
      getFincaTemperatura(finca.id, frecuencia, fechaDesde, fechaHasta)
        .then((res) => {

          res.data && setFechas(res.data.fechas);
          res.data && setMuestras(res.data.muestras_agrupadas);
          res.data && setNumMuestras(res.data.num_muestras)

          console.log(res.data)
          setLoadingMuestras(false);

        })
        .catch((err) => {
          // console.log(err)
        }
        )

    };

    fetchData();
  }, [frecuencia, fechaDesde, fechaHasta])

  const state = {
    options: {
      chart: {
        id: "basic-bar",
        height: 250,
        type: 'line',
        toolbar: {
          show: false
        }
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',

        // curve: 'straight',
        width: 2
      },

      xaxis: {
        categories: fechas, // [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
        type: 'datetime',
        // tickPlacement: 'on',
        //tickAmount: 15,
        labels: {
          // rotate: 15,
          formatter: function (value, timestamp, opts) {
            const m = new Date(value);
            const dateString = m.getUTCDate().toString().padStart(2, "0") + "/" + (m.getUTCMonth() + 1).toString().padStart(2, "0") + "/" + m.getUTCFullYear() + " " + m.getUTCHours().toString().padStart(2, "0") + ":" + m.getUTCMinutes().toString().padStart(2, "0") + ":" + m.getUTCSeconds().toString().padStart(2, "0");

            return dateString
            // return opts.dateFormatter(new Date(timestamp), 'dd MMM')
          }
        }
      }
    }
  }

  const Grafica = () => {
    return (
      numMuestras === 0 ?
        <div className="alert alert-info mt-3">No hay datos para mostrar</div>
        :
        <>
          {muestras && fechas && <Chart
            options={state.options}
            series={muestras}
            type="line"
          />}
        </>
    )
  }

  const TablaDatos = () => {
    console.log(muestras)
    return (
      numMuestras === 0 ?
        <></>
        :
        <>

        </>
    )
  }

  return (
    <div>
      {finca.nombre}
      {
        loadingMuestras ?

          <Cargando /> :

          <>
            <Grafica />

            <TablaDatos />
          </>
      }

    </div>
  )
}
