import axios from 'axios';
import {
	BASE_URL_SERVICE,
} from '../constants'

async function getRiegoHistorico({ fincaId, channelId, fechaDesde, fechaHasta }) {
	try {
		var config = {
			headers: {
				'Access-Control-Allow-Origin': '*',
				'content-type': 'text/json'
			}
		};

		const response = await axios.get(BASE_URL_SERVICE + 'riego_historico', {
			params: {
				fincaId,
				channelId,
				fechaDesde,
				fechaHasta
			}
		}, config);

		return response;
	} catch (error) {
		return undefined;
		// throw error;
	}

}

export default getRiegoHistorico;